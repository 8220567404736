import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../components/CAT/layout'

import pic11 from '../../assets/images/pic11.jpg'

const Generic = (props) => (
    <Layout>
        <Helmet>
            <title>Unidans Project</title>
            <meta name="description" content="Generic Page" />
        </Helmet>

        <div id="main" className="alt">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1> Objectiu del projecte </h1>
                    </header>
                    <span className="image main"><img src={pic11} alt="" /></span>
                    <p> La temàtica principal del projecte
és la promoció de l’educació en/a través de l’esport, parant especial atenció al
desenvolupament d’habilitats per part dels entrenadors que treballen en l’àmbit de l’esport
adaptat/unificat en general, i de la dansa adaptada/unificada en particular.
<br/><br/> Això està directament
relacionat amb la inclusió social i la igualtat d’oportunitats en l’esport; raó per la qual part de
les activitats a nivell local es desenvoluparan al voltant d’aquesta temàtica.

<br/><br/> L’objectiu del projecte és el de promoure i donar suport als
instruments d’aprenentatge mutu, la investigació interdisciplinària que apunta a un marc
analític basat en l’evidencia, i l’intercanvi de coneixements i bones pràctiques en l’àmbit de la
dansa o l’esport adaptada/unificada. Això es vol fer a través de seminaris i reunions de grups
d’experts en aspectes relacionats amb la dansa i la inclusió social, juntament amb les
corresponents accions de difusió i connexió dels resultats en qüestió a cada un dels països
participants.</p>
                </div>
            </section>
        </div>

    </Layout>
)

export default Generic